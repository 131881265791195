import React, {Component} from 'react';
import HeaderBannerContainer from "../../components/HeaderBannerContainer/HeaderBannerContainer";
import contactus from '../../assets/HeaderImages/contactus.png';
import LetsWorkTogether from "../../components/LetsWorkTogether";
import './Contact.scss';
import TextfieldMui from "../../components/Inputs/TextfieldMui/TextfieldMui";
import UnderLine from "../../components/UnderLine";
import Card from "../../components/Card/Card";
import {Email, Instagram, LinkedIn, PhoneNumber, User} from '../../assets/SvgIcons';
import ButtonMui from "../../components/Buttons/ButtonMui";
import superagent from "superagent/lib/client";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataObject: {}
        }
    }

    onChange = (e) => {
        let dataObject = this.state.dataObject;
        Object.assign(dataObject, {[e.target.name]: e.target.value})
        this.setState({dataObject});
    }

    getInTouch = () => {
        superagent
            .post('/api/getInTouch')
            .send({...this.state.dataObject}) // sends a JSON post body
            .end((err, result) => {

                console.log("resultText", result && result.text === 'OK');

                if(result && result.text === 'OK'){
                    this.setState({dataObject: {}})
                }
            })
    }

    render() {
        return (
            <div>
                <HeaderBannerContainer img={contactus} title={'Contact Us'}/>
                <div className={'contact-us-container'}>
                    <div className={'contact-top'}>
                        <div className={'contact-heading'}>
                            <div>Let's Get in Touch</div>
                            <UnderLine width={135} borderHeight={2}/>
                        </div>
                        <div className={'contact-description'}>
                            Have questions about pricing or services? Fill out the form and our team will be in touch
                            with you shortly.
                        </div>
                    </div>
                    <div className={'contact-bottom'}>
                        <div className={'contact-information'}>
                            <Card contentAlign={'left'} underlineHeader={true} underlineWidth={80} content={<div className={'contact-card'}>
                                <div className={'contact-card-email'}>
                                    <div><Email/> <span>Email</span></div>
                                    <div>code@trifecta.com</div>
                                </div>
                                <div className={'contact-card-phone'}>
                                    <div><PhoneNumber/> <span>Phone Number</span></div>
                                    <div>+389negokazuvame</div>
                                </div>
                                <div className={'contact-card-socials'}>
                                    <div><User/> <span>Socials</span></div>
                                    <div><Instagram/><LinkedIn/></div>
                                </div>
                            </div>} header={'Contact Information'}/>
                        </div>
                        <div className={'contact-inputs'}>
                            <div className={'row'}>
                                <TextfieldMui name={'name'} value={this.state.dataObject.name} onChange={this.onChange} label={'enter your name*'}/>
                                <TextfieldMui name={'email'} value={this.state.dataObject.email} onChange={this.onChange} label={'enter your email*'}/>
                            </div>
                            <div className={'row'}>
                                <TextfieldMui name={'number'} value={this.state.dataObject.number} onChange={this.onChange} label={'enter your phone number*'}/>
                                <TextfieldMui name={'companyName'} value={this.state.dataObject.companyName} onChange={this.onChange} label={'enter your company name*'}/>
                            </div>
                            <div className={'row'}>
                                <TextfieldMui name={'message'} value={this.state.dataObject.message} onChange={this.onChange} label={'enter your message*'} fullWidth multiline rows={12}/>
                            </div>
                        </div>
                    </div>
                    <div className={'contact-submit-section'}>
                        <ButtonMui onClick={this.getInTouch} label={'GET IN TOUCH'} width={354} style={{height: 86}} fontSize={20}/>
                    </div>
                </div>
                <LetsWorkTogether dontShowButton/>
            </div>
        );
    }
}

export default Contact;