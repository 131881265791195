import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useLocation} from "react-router-dom";
import {NavLine} from "../../assets/SvgIcons";
import './tabs.scss';

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            {...props}
        />
    );
}

const tabs = {
    '/home': {name: 'Home', path: '/home', index: 0},
    '/about_us': {name: 'About Us', path: '/about_us', index: 1},
    '/services': {name: 'Services', path: '/services', index: 2},
    '/contact': {name: 'Contact', path: '/contact', index: 3},
    '/': {name: 'Home', path: '/', index: 0, hidden: true},
}

export default function NavTabs() {
    const Location = useLocation();
    const Tab = tabs[Location.pathname];
    const [value, setValue] = React.useState(Tab.index);
    React.useEffect(() => {
        global.CodeTrifecta.History.listen(({location, action}) => {
            const tab = tabs[location.pathname];
            const newValue = tab ? tab.index : value;
            setValue(newValue);
        });
    }, [])

    const handleChange = (event, newValue) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event))
        ) {
            setValue(newValue);
            global.CodeTrifecta.Navigate(event.target.pathname)
        }
    };



    return (
        <Box sx={{width: '363px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example"
                  TabIndicatorProps={{
                      children: <div className={'svg_parent_container'}>
                          <div className={'svg_container'}>
                              <NavLine/></div>
                      </div>
                  }}
            >
                {Object.values(tabs).filter(c => !c.hidden).map(tab => <LinkTab key={tab.name} label={tab.name}
                                                                                href={tab.path}/>)}
            </Tabs>
        </Box>
    );
}