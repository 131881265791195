import Card from "../../components/Card/Card";
import NavigationBar from "../../components/Navigation/NavigationBar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HeaderBannerContainer from "../../components/HeaderBannerContainer/HeaderBannerContainer";
import img2 from '../../assets/HeaderImages/img2.png';
import ButtonMui from "../../components/Buttons/ButtonMui";
import image1 from '../../assets/ImgTextContainerImages/image1.png';
import ImageTextContainer from "../../components/ImageTextContainer/ImageTextContainer";
import React, {Component} from 'react';
import LetsWorkTogether from "../../components/LetsWorkTogether";
import TextfieldMui from "../../components/Inputs/TextfieldMui/TextfieldMui";

class AboutUs extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const imageTextProps = {
            title: 'Who Are We?',
            description: 'With a focus on user experience, our team of expert website developers is dedicated to creating seamless, visually stunning websites that captivate your audience and drive results. Whether you\'re a startup, a small business, or a large enterprise, we have the skills and expertise to design and develop websites that not only look great but also deliver exceptional performance.',
            descriptionAlign: 'left',
            img: image1
        }
        const headerBannerProps = {
            img: img2,
            title: 'About Us'
        }
        return (
            <div>
                <div>
                    <HeaderBannerContainer {...headerBannerProps}/>
                    <ImageTextContainer {...imageTextProps}/>
                    <LetsWorkTogether topLine/>
                </div>
            </div>
        );
    }
}

export default AboutUs;