import React, {Component} from 'react';
import './Home.scss';
import ButtonMui from "../../components/Buttons/ButtonMui";
import home from '../../assets/HeaderImages/home.png';
import Card from "../../components/Card/Card";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LetsWorkTogether from "../../components/LetsWorkTogether";

class Home extends Component {
    render() {
        const cardProps = [
            {
                header: 'Data Science',
                content: 'Data science provides tools and techniques to extract valuable insights from data. This allows organizations and individuals to make data-informed decisions rather than relying solely on intuition or anecdotal evidence.',
                contentAlign: 'justify'
            },
            {
                header: 'Web Development',
                headerAlign: 'left',
                content: 'We will offer you the freedom to create a digital presence that is uniquely yours, tailored to your specific needs, and capable of growing and evolving with your goals and ambitions. It\'s an investment in your online success and brand identity.',
                contentAlign: 'justify',
            },
            {
                header: 'UI/UX',
                content: 'UI and UX are two closely related but distinct aspects of designing digital products, such as websites, mobile apps, and software applications. They play a critical role in creating user-friendly, visually appealing, and effective digital experiences.',
                contentAlign: 'justify',
            }];
        return (
            <div className={'home-container'}>
                <div className={'home-top'}>
                    <div className={'home-left-side'}>
                        <div className={'home-header'}>
                            <div>CodeTrifecta-where your digital dreams become a reality.</div>
                        </div>
                        <div className={'home-description'}>
                            <div>We're here to simplify the web development process for you.
                                Our goal is to empower businesses and individuals like you to effortlessly create
                                stunning,
                                functional websites that captivate your audience. Let's connect and embark on a journey
                                to elevate your online presence together.
                            </div>
                        </div>
                        <div>
                            <ButtonMui label={'GET IN TOUCH'} width={354} style={{height: 86}} fontSize={20}/>
                        </div>
                    </div>
                    <div className={'home-right-side'}>
                        <img alt={'image'} src={home}/>
                    </div>
                </div>
                <div className={'home-bottom'}>
                    <div>
                        <div className={'home-alternative-header'}>WHY CHOOSE US?</div>
                    </div>
                    <div>
                        <div>Our team of expert developers specializes in creating websites that not only look stunning
                            but are also intuitive and easy to navigate,
                            ensuring an exceptional user experience. Whether you're a business looking to boost your
                            online presence or an
                            individual with a creative vision, we're your trusted partner in crafting websites that
                            engage, convert, and leave a lasting impression.
                        </div>
                    </div>
                    <div className={'home-services'}>SERVICES</div>
                    <div className={'home-cards-wrapper'}>
                        {cardProps.map((c, index) => (
                            <div className={'home-cards'} key={index}>
                                <Card header={c.header} content={c.content} contentAlign={c.contentAlign}
                                      buttonProps={c.buttonProps} button={c.button} buttonAlign={c.buttonAlign}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'home-submit-section'}>
                    <ButtonMui label={'SEE MORE'} width={354} style={{height: 86}} fontSize={20} variant={'standard'} endIcon={<ArrowForwardIcon/>}/>
                </div>
                <LetsWorkTogether/>
            </div>
        );
    }
}

export default Home;