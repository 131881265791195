import './App.scss';
import Card from "./components/Card/Card";
import UnderLine from "./components/UnderLine";
import Footer from "./components/Footer/Footer";
import NavigationBar from "./components/Navigation/NavigationBar";
import React from "react";
import {createBrowserRouter, Navigate, Outlet, Route, RouterProvider, Routes} from "react-router-dom";
import Home from "./routes/Home/Home";
import AboutUs from "./routes/AboutUs/AboutUs";
import Services from "./routes/Services/Services";
import Contact from "./routes/Contact/Contact";
import {NavigateHook} from "./components/MainHooks";

const router = createBrowserRouter([
    {path: "*", Component: Root},
]);

function App() {
    return <RouterProvider router={router}/>;
}

function Root() {
    return (
        <Routes>
            <Route exact path={'/'} element={<Layout/>}>
                <Route path={'/home'} element={<Home/>}/>
                <Route path={'/about_us'} element={<AboutUs/>}/>
                <Route path={'/services'} element={<Services/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
                <Route path={'/'} element={<Navigate to={'/home'}/>}/>
            </Route>
            <Route path={'/*'} element={<Navigate to={'/home'}/>}/>
        </Routes>
    );
}

const Layout = () => {
    return (
        <div className="App">
            <NavigationBar/>
            <div className={'app_content'}>
                <Outlet/>
                <NavigateHook/>
                <Footer/>
            </div>
        </div>
    )
}

export default App;