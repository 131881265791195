import React from 'react';
import {Logo} from "../../assets/SvgIcons";
import './logo_container.scss';

function LogoContainer(props) {
    return (
        <div className='logo_container'>
            <Logo/>
        </div>
    );
}

export default LogoContainer;