import React, {Component} from 'react';
import LogoContainer from "../LogoContainer/LogoContainer";
import './navigation_bar.scss';
import NavTabs from "../Tabs/Tabs";

class NavigationBar extends Component {
    render() {
        return (
            <div className='nav_container'>
                <div className='nav_bar'>
                    <LogoContainer/>
                    <NavTabs/>
                </div>
            </div>
        );
    }
}

export default NavigationBar;