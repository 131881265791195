import React, {Component} from 'react';
import ButtonMui from "./Buttons/ButtonMui";
import './LetsWorkTogether.scss';

class LetsWorkTogether extends Component {
    render() {
        return (
            <div className={'lets_work_together_main_container'}>
                <div className={`lets_work_together_container ${this.props.topLine ? 'border_top' : ''}`}>
                    <div className={'lwtc_heading'}>
                        <div className={'lwtc_text '}>
                            LET'S WORK TOGETHER!
                        </div>
                    </div>
                    <div className={'lwtc_button'}>
                        {
                            !this.props.dontShowButton &&
                            <ButtonMui label={'GET IN TOUCH'} width={354} style={{height: 86}} fontSize={20}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default LetsWorkTogether;