import React from 'react';
import {TextField as MaterialTextField} from '@mui/material';
import PropTypes from "prop-types";
import './TextfieldMui.scss';

function TextfieldMui(props) {
    return (
        <MaterialTextField
            className={'textfield-mui'}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            sx={{...props.sx, '& input, & textarea':{cursor: props.read_only ? 'default' : 'text'}}}
            {...props}
            inputProps={{
                readOnly: props.read_only,
            }}
        />
    );
}

TextfieldMui.propTypes = {
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
    inputProps: PropTypes.object,
    style: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(['success', 'error', 'primary', 'secondary']),
    error: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    helperText: PropTypes.node,
    fullWidth: PropTypes.bool,
    InputLabelProps: PropTypes.object,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    type: PropTypes.string,
    defaultValue: PropTypes.any,
    autoFocus: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.oneOf(['number', 'string']),
    maxRows: PropTypes.oneOf(['number', 'string']),
    required: PropTypes.bool,
    id: PropTypes.string,
    readonly: PropTypes.bool
};

TextfieldMui.defaultProps = {
    variant: 'outlined',
    color: 'primary',
    size: 'medium',
};

export default TextfieldMui;
