import React, {Component} from 'react';
import PropTypes from "prop-types";
import './card.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Button} from "@mui/material";
import UnderLine from "../UnderLine";

class Card extends Component {
    render() {
        const { buttonProps } = this.props;
        return (
            <div className={`card`}>
                <div className={'card-header'}>
                    <span className={`header ${this.props.headerAlign}`}>{this.props.header}</span>
                    {this.props.underlineHeader && <UnderLine width={this.props.underlineWidth} borderHeight={2}/>}
                </div>
                <div className={'card-content'}>
                    <div className={`content ${this.props.contentAlign}`}>{this.props.content}</div>
                </div>
                <div className={`card-button`}>
                    <div className={`button ${this.props.buttonAlign}`}><Button {...buttonProps}>{this.props.button}</Button></div>
                </div>
            </div>
        );
    }
}

Card.propTypes = {
    header: PropTypes.string.isRequired,
    headerAlign: PropTypes.oneOf(['left', 'center', 'right']),
    underlineHeader: PropTypes.bool,
    underlineWidth: PropTypes.number,
    content: PropTypes.string.isRequired,
    contentAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
    button: PropTypes.string,
    buttonAlign: PropTypes.oneOf(['left', 'center', 'right']),
}

Card.defaultProps = {
}

export default Card;