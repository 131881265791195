import React, {Component} from 'react';
import './image_text_container.scss';
import PropTypes from "prop-types";

class ImageTextContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const props = {...this.props}
        return (
            <div className={'image-text-wrapper'}>
                <div className={'image-text-container'}>
                    <div className={'text-content'}>
                        <div className={`title`}>
                            {props.title}
                        </div>
                        <div className={`description ${props.descriptionAlign}`}>
                            {props.description}
                        </div>
                    </div>
                    <div className={'image-content'}>
                        <div className={'first-square '}></div>
                        <div className={'second-square'}></div>
                        <img className={'main-image'} alt={'image'} src={props.img}/>
                    </div>
                </div>
            </div>
        );
    }
}

ImageTextContainer.propTypes = {
    title: PropTypes.string.isRequired,
    titleAlign: PropTypes.string,
    img: PropTypes.string,
    description: PropTypes.string.isRequired,
    descriptionAlign: PropTypes.oneOf(['left', 'center', 'right', 'justify'])
}

export default ImageTextContainer;