import React, {Component} from 'react';
import './header_banner_container.scss';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class HeaderBannerContainer extends Component {
    constructor(props) {
        super(props);
    }
    handlePage = () => {
        const currentPage = window.location.pathname.split('/')[1];
        switch(currentPage){
            case 'home': {
                return 'Home';
            }
            case 'about_us': {
                return 'About Us';
            }
            case 'services': {
                return 'Services';
            }
            case 'contact': {
                return 'Contact';
            }
        }
    }
    render() {
        const props = {...this.props};
        return (
            <div className={'header-banner-container'}>
                <img alt={'image'} src={props.img}/>
                <div className={'content-container'}>
                    <div className={`content`}>
                        <div className='title'>
                            {props.title}
                        </div>
                        <div className='sections'>
                            <Link to={'/home'}>Home</Link>
                            <span> | </span>
                            <span>{this.handlePage()}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HeaderBannerContainer.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default HeaderBannerContainer;