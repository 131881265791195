import React, {Component} from 'react';
import img1 from "../../assets/HeaderImages/img1.png";
import HeaderBannerContainer from "../../components/HeaderBannerContainer/HeaderBannerContainer";
import './services.scss';
import Card from "../../components/Card/Card";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LetsWorkTogether from "../../components/LetsWorkTogether";

class Services extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const headerBannerProps = {
            img: img1,
            title: 'Services'
        }
        const cardProps = [
            {
                header: 'Web Development',
                headerAlign: 'left',
                content: 'We will offer you the freedom to create a digital presence that is uniquely yours, tailored to your specific needs, and capable of growing and evolving with your goals and ambitions. It\'s an investment in your online success and brand identity.',
                contentAlign: 'justify',
                button: 'Learn More',
                buttonAlign: 'right',
                buttonProps: {
                    variant: 'standard',
                    endIcon: <ArrowForwardIcon/>
                }
            },
            {
                header: 'Desktop Applications',
                headerAlign: 'left',
                content: 'If you are planning to build software for these or other users requiring a desktop application, we will provide you with a list of tools and methods that are popular now on the market and frequently used by our experienced team.',
                button: 'Learn More',
                buttonAlign: 'right',
                buttonProps: {
                    variant: 'standard',
                    endIcon: <ArrowForwardIcon/>
                },
                contentAlign: 'justify'
            },
            {
                header: 'Data Science',
                content: 'Data science provides tools and techniques to extract valuable insights from data. This allows organizations and individuals to make data-informed decisions rather than relying solely on intuition or anecdotal evidence.',
                button: 'Learn More',
                buttonAlign: 'right',
                buttonProps: {
                    variant: 'standard',
                    endIcon: <ArrowForwardIcon/>
                },
                contentAlign: 'justify'
            },
            {
                header: 'UI/UX',
                content: 'UI and UX are two closely related but distinct aspects of designing digital products, such as websites, mobile apps, and software applications. They play a critical role in creating user-friendly, visually appealing, and effective digital experiences.',
                button: 'Learn More',
                buttonAlign: 'right',
                buttonProps: {
                    variant: 'standard',
                    endIcon: <ArrowForwardIcon/>
                },
                contentAlign: 'justify',
            }
        ]
        return (
            <div className={'services-container'}>
                <div className={'services-header-wrapper'}>
                    <HeaderBannerContainer {...headerBannerProps} />
                </div>
                <div className={'services-cards-wrapper'}>
                    {cardProps.map((c, index) => (
                        <div key={index}>
                            <Card header={c.header} content={c.content} contentAlign={c.contentAlign} buttonProps={c.buttonProps} button={c.button} buttonAlign={c.buttonAlign}/>
                        </div>
                    ))}
                </div>
                <LetsWorkTogether topLine/>
            </div>
        );
    }
}

export default Services;