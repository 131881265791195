import React, {Component} from 'react';
import './Footer.scss';
import LogoContainer from "../LogoContainer/LogoContainer";
import ButtonMui from "../Buttons/ButtonMui";
import UnderLine from "../UnderLine";
import {Instagram, LinkedIn} from "../../assets/SvgIcons";

class Footer extends Component {
    render() {
        return (
            <div className={'footer_container'}>
                <div className={'fc_middle'}>
                    <div className={'fcm_logo_container'}>
                        <LogoContainer/>
                        <div>where your digital dreams become a reality.</div>
                    </div>
                    <div className={'fcm_links_container'}>
                        <div className={'fcm_quick_links'}>
                            <div className={'fc_heading'}>
                                Quick Links
                                <UnderLine width={52} borderHeight={2}/>
                            </div>
                            <a href={'#'} className={'fc_link'}>
                                Home
                            </a>
                            <a href={'#'} className={'fc_link'}>
                                About us
                            </a>
                            <a href={'#'} className={'fc_link'}>
                                Services
                            </a>
                        </div>
                        <div className={'fcm_services'}>
                            <div className={'fc_heading'}>
                                Services
                                <UnderLine width={52} borderHeight={2}/>
                            </div>
                            <a href="#" className={'fc_link'}>
                                Web Development
                            </a>
                            <a href="#" className={'fc_link'}>
                                Desktop Applications
                            </a>
                            <a href="#" className={'fc_link'}>
                                Data Science
                            </a>
                            <a href="#" className={'fc_link'}>
                                UX/UI
                            </a>
                        </div>
                        <div className={'fcm_contact'}>
                            <div className={'fc_heading'}>
                                Contact
                                <UnderLine width={52} borderHeight={2}/>
                            </div>
                            <div className={'fc_link'}>
                                codetrifect@gmail.com
                            </div>
                            <div className={'fc_link'}>
                                +38971302910
                            </div>
                        </div>
                        <div className={'fcm_follow_us'}>
                            <div className={'fc_heading'}>
                                Follow Us
                                <UnderLine width={52} borderHeight={2}/>
                            </div>
                            <div className={'fcmfu_icons'}>
                                <a href="#" className={'fc_link'}><LinkedIn/></a>
                                <a href="#" className={'fc_link'}><Instagram/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'fc_bottom'}>
                    <div>Made By: CodeTrifecta  |  All Rights Reserved &copy; 2023</div>
                </div>
            </div>
        );
    }
}

export default Footer;